import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import has from 'lodash/has';
import xor from 'lodash/xor';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import Modal from 'views/components/Modal';
import useFormBuilder from 'components/FormBuilder';
import Button from '@material-ui/core/Button/Button';

export type Fields = {
  accountNumber: string;
  email: string;
  scope: string[];
  tags: string[];
  isPrivate: boolean;
  expire: boolean;
  date: string;
};
type ModalProps = {
  onSubmit: (values: Fields) => void;
  filters: Fields;
  hasPrivateCommunity: boolean;
};

const AddLimitedAccessModal = () => {
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const dispatch = useDispatch<Dispatch>();
  const { filters, onSubmit, hasPrivateCommunity } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );
  const [errors, setErrors] = useState<string[]>([]);

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'input',
      hideName: true,
      id: 'accountNumber',
      placeholder: 'Enter external team account number *',
    },
    {
      elementType: 'input',
      hideName: true,
      id: 'email',
      placeholder: 'Enter email address of external team member *',
    },
    {
      elementType: 'selectTag',
      id: 'scope',
      name: 'Scope *',
      placeholder: 'Select which scopes to include',
      defaultOptions: ['manual_analysis'],
      bold: false,
      hideName: true,
    },
    {
      elementType: 'inputTag',
      hideName: true,
      name: 'Custom Tags (Optional)',
      id: 'tags',
      placeholder: 'Add custom tags',
    },
    {
      elementType: 'switch',
      name: 'Is Private',
      id: 'isPrivate',
      defaultValue: hasPrivateCommunity,
      show: hasPrivateCommunity,
      defaultOptions: ['Public', 'Private'],
      bold: false,
    },
    {
      name: 'Expiration Date',
      hideName: true,
      combine: [
        {
          elementType: { name: 'input', type: 'date' },
          placeholder: 'Expiration Date *',
          id: 'date',
          defaultValue: '',
        },
        { elementType: 'checkbox', name: 'Never Expire', id: 'expire', defaultValue: false },
      ],
    },
  ]);

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Add user with limited access this account</h2>
        <FormComponent
          customClass={classes.form}
          filters={filters}
          onSubmit={(values) => {
            const validationErrors: string[] = [];

            if (values.external_team_account_number === '') {
              validationErrors.push('Please enter external team account number');
            }
            if (values.external_email_address === '') {
              validationErrors.push('Please enter email address of external team member');
            }
            if (!values.scope || values.scope.length === 0) {
              validationErrors.push('Please select which scopes to include');
            }

            setErrors(validationErrors);
            if (validationErrors.length) return;

            onSubmit(values);
          }}
          footerRender={({ dirtyFields, formState }) => {
            return (
              <div style={{ width: '100%' }}>
                <div style={{ paddingBottom: '1rem' }}>
                  {errors.map((error) => (
                    <div key={error} style={{ color: 'red' }}>
                      {error}
                    </div>
                  ))}
                </div>
                <div css={classes.mfaMessage}>
                  <p>
                    Pressing "Submit" you'll be redirected to the PolySwarm authO login
                    page to enter your two factor authentication for confirmation.
                  </p>
                </div>
                <div css={classes.buttonsContainer}>
                  <Button
                    onClick={() => {
                      _closeModal();
                    }}
                    variant='outlined'
                    color='primary'
                    css={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      formState.isSubmitting ||
                      !(
                        ['accountNumber', 'scope', 'email'].every((item) =>
                          has(dirtyFields, item)
                        ) && !isEmpty(xor([has(dirtyFields, 'expire')], [has(dirtyFields, 'date')]))
                      )
                    }
                    type='submit'
                    variant='contained'
                    color='primary'
                    css={classes.button}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '3rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      width: '65rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.3rem',
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
    },
    mfaMessage: {
      paddingBottom: '2rem',
      textAlign: 'center',
      fontSize: '1.4rem',
    },
    button: {
      padding: '0.7rem 1rem !important',
    },
  },
  light: {},
  dark: {},
});
export default AddLimitedAccessModal;
