import React, { useEffect, useCallback, useContext, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { contextAccount } from 'state/auth/selectors';
import { Dispatch } from 'state/types/thunk';
import { AccountContext, EAccountPlans, Account } from 'models/Account';
import { User } from 'models/User';
import { RootState } from 'state/root';
import { store } from 'state/store';
import { getSelf, getUserAccount } from 'state/user/actions';
import { getAccountContext } from 'state/account/actions';
import { TeamActionName } from 'state/team/types';
import { UserActionName } from 'state/user/types';
import { AccountActionName } from 'state/account/types';

export interface ExtendedUserInfo extends User {
  accountPlan: string;
  context: AccountContext | null;
  paymentCustomerId?: string | null;
  paymentSubscriptionId?: string | null;
  paymentPlan?: Account['paymentPlan'] | null;
  refreshUser: () => Promise<void>;
}

export interface UserProps {
  user: ExtendedUserInfo;
}

type StateProps = Omit<ExtendedUserInfo, 'refreshUser'>;

const defaultUserValues: ExtendedUserInfo = {
  id: -1,
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  accountNumber: -1,
  organization: null,
  company: '',
  title: null,
  teams: [],
  mfaEnabled: false,
  accountPlan: EAccountPlans.anonymous,
  context: null,
  paymentCustomerId: null,
  paymentSubscriptionId: null,
  paymentPlan: null,
  refreshUser: () => new Promise((resolve) => resolve()),
};

/**
 * A list of actions to watch when a user or account context is updating
 */
export const combinedUserActions = [
  UserActionName.GET_USER,
  UserActionName.REFRESH_USER_ACCOUNT,
  AccountActionName.GET_ACCOUNT_CONTEXT,
  TeamActionName.GET_TEAM_ACCOUNT,
];

/**
 * App-level theme context
 */
export const UserContext = React.createContext<ExtendedUserInfo>(defaultUserValues);

/**
 * User context hook.
 */
export const useUser = () => useContext(UserContext);

/**
 * The UserProvider component
 *
 * Provides user data context to child components
 */
const UserProvider: FC = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();
  const ctx = contextAccount(store);

  const user = useSelector<RootState, StateProps>(
    ({ user: { profile, account }, account: { context } }) => ({
      id: profile ? profile.id : -1,
      name: profile ? profile.name : '',
      firstName: profile ? profile.firstName : '',
      lastName: profile ? profile.lastName : '',
      email: profile ? profile.email : '',
      username: profile ? profile.username : '',
      accountNumber: profile ? profile.accountNumber : -1,
      organization: profile ? profile.organization : null,
      company: profile ? profile.organization || '' : '',
      title: profile ? profile.title : null,
      teams: profile ? profile.teams : [],
      teamsExternal: profile ? profile.teamsExternal : [],
      mfaEnabled: profile ? profile.mfaEnabled : false,
      accountPlan: account?.plan?.name ? account.plan.name : EAccountPlans.anonymous,
      paymentCustomerId: account ? account.paymentCustomerId : null,
      paymentSubscriptionId: account ? account.paymentSubscriptionId : null,
      paymentPlan: account ? account.paymentPlan : null,
      manualAnalysisContextId: profile?.manualAnalysisContextId,
      context,
    })
  );

  const _refreshUser = useCallback(async () => {
    await dispatch(getSelf());
    await dispatch(getUserAccount());
    await dispatch(getAccountContext(ctx));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.id !== -1) {
      _refreshUser();
    }
  }, [user.id, _refreshUser]);

  return (
    <UserContext.Provider value={{ refreshUser: _refreshUser, ...user }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;
